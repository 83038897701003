.slide {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.imageContainer {
  @media screen and (max-height: 600px) {

    img,
    svg {
      max-height: calc(35vh - 57px);
      max-width: calc(100vw - 40px);
    }
  }

  img,
  svg {
    max-height: calc(50vh - 57px);
    max-width: calc(100vw - 40px);
  }
}

.textContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
