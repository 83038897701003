@import "../../variables.scss";

.page {
  composes: page from "../../style/index.scss";
  justify-content: space-between;
  padding: $spacing-xs;
  background-color: $background-color;
}

.slider {

  > div {
    height: 100%;
  }

  ul {
    height: 100%;
  }
}

.sliderContainer {
  text-align: center;
  padding-bottom: $spacing-xxs;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > div {
    flex-grow: 1;
  }
}
