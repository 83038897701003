@import  "../../../../variables.scss";

.dot {
  outline: 0;
  padding: 10px;
  border: 0;
  list-style: none;
  font-size: 0;
}

.dotInner {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $disabled-text-color;

  &.active {
    background-color: $active-color;
  }
}
